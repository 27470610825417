/* eslint-disable max-classes-per-file */
/* eslint-disable react/no-multi-comp */
import React, { useContext, useState, useEffect } from 'react'


import { ReactComponent as LoaderIcon } from '../assets/loader.svg'

import { AreaChart, Area, XAxis, YAxis, CartesianGrid, ResponsiveContainer, ReferenceDot, Tooltip, ReferenceLine } from 'recharts';
import { RouteContext } from '../config/RouteContext';
import { calculateTotalAltitude, generateChartTicks } from '../utils/calculations';

import * as turf from '@turf/turf'
import styles from '../Style/Tooltip.module.css'
import { API_URL } from '../config/config';

import { startIconHref, endIconHref } from '../assets/svgPaths';
import CustomReferenceDot from './CustomReferenceDot';
import StatsTooltip from './Tooltip';
import {isMobileScreen, mapWidgetElement } from '../utils/responsive';

const GraphicChart = props => {

    const { setCurrentPoi, language, routes, geodata } = useContext(RouteContext);
    const { chartIndex, setChartIndex, setChartLastIndex, chartLastIndex, height } = props;
    const [poiIn, setPoiIn] = useState([]);
    const [alt, setAlt] = useState([]);
    const [maximumAltitude, setMaxAltitude] = useState(0);

    //Near poi in geojson
    //Get all poi and build geodata array for the chart
    useEffect(poiIndex => {
        let maxAlt = 0;
        geodata.geodataArray.map(point => {
            if (point.elevation > maxAlt) {
                maxAlt = point.elevation;
            }
            return null;
        })
        setMaxAltitude(maxAlt);

        poiIndex = []

        const getCurrentPoiIndex = (line, pt, snapped) => {
            const poiData = []
            for (const poi of routes.pointsOfInterest) {
                poiData.push({
                    point: [poi.longitude, poi.latitude],
                    img: `${API_URL}${poi.type.mapIcon.contentUrl}`,
                    '@id': poi['@id'],
                    name: poi.name,
                    description: poi.description,
                    subname: poi.type.name,
                    iconUrl: `${poi.type.icon.contentUrl}`,
                    tooltipImg: `${poi.image.contentUrl}`,
                })
            }

            // The X and Y points of the graph are given by the index in the line array and from the geodata latitude 
            line = turf.lineString(routes.geoJson.coordinates);
            for (const points of poiData) {
                pt = turf.point([points.point[0], points.point[1]]);
                snapped = turf.nearestPointOnLine(line, pt, { units: 'kilometers' });
                const idx = snapped.properties.index;
                poiIndex.push({
                    ...points,
                    point: [snapped.properties.index, geodata.geodataArray[idx].elevation],
                    latitude: geodata.geodataArray[idx].lat,
                    longitude: geodata.geodataArray[idx].lon,
                })
            }

            setPoiIn(poiIndex)
        }

        getCurrentPoiIndex()

    }, [])// eslint-disable-line react-hooks/exhaustive-deps




    useEffect(() => {
        const altitudeArray = calculateTotalAltitude(routes);
        setAlt(altitudeArray);
    }, [alt.length, routes.geoJson.coordinates])// eslint-disable-line react-hooks/exhaustive-deps

    const dotFromMap = (props) => {
        const { cx, cy, index } = props;
        if (chartIndex === index || chartLastIndex === index) {
            return (
                <circle cx={cx} cy={cy} r={10} fill={'#00AB84'} stroke="white" strokeWidth={5}></circle>
            )
        }
        return false;
    }

    const dotChart = {
        r: 10,
        stroke: "white",
        strokeWidth: 5,
    }


    const renderRefLines = () => {
        const ticks = generateChartTicks();
        const xAxisLength = ticks.length;
        let refLines = [];
        for (let i = 0; i < xAxisLength; i++) {
            if (geodata.geodataArray[ticks[i]] !== undefined) {
                refLines.push(
                    <ReferenceLine stroke='#494D69' label=""
                        segment={[{ x: ticks[i], y: 0 }, { x: ticks[i], y: geodata.geodataArray[ticks[i]].lat }]} />
                )
            }

        }
        return refLines;
    }



    if (geodata.geodataArray.length === 0 || alt.length === 0) {
        return (
            <div className={styles["loader"]}>

                <LoaderIcon />
                <h1>Loading...</h1>
            </div>
        )
    }
    let width = mapWidgetElement().clientWidth;
    return (
        <ResponsiveContainer width="100%" height={height}>
            <AreaChart data={geodata.geodataArray}
                margin={{ top: isMobileScreen() ? 75 : 45, left: isMobileScreen() ? 0 : 20, right: isMobileScreen() ? 20 : 30, bottom: isMobileScreen() ? 0 : 20 }}
            >
                <defs>
                    {/* <linearGradient id="line" x1="0" y1="0" x2="100%" y2="0">
                        <stop offset="0%" stopColor="#00AB84" opacity={0} />
                        <stop offset={`90%`} stopColor="#00AB84" opacity={0} />
                        <stop offset={`90%`} stopColor="#D17630" opacity={0} />
                        <stop offset="100%" stopColor="#D17630" opacity={0} />
                    </linearGradient> */}

                    <linearGradient id="line" x1="0" y1="0" x2="100%" y2="0">
                        <stop offset="0%" stopColor="#00AB84" opacity={0} />
                        <stop offset={`100%`} stopColor="#00AB84" opacity={0} />
                    </linearGradient>

                    <linearGradient id="color" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="0%" stopColor="#00AB84" stopOpacity={0.4} />
                        <stop offset="100%" stopColor="#00AB84" stopOpacity={0} />
                    </linearGradient>

                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="0%" stopColor="#129a74" stopOpacity={0} />
                        <stop offset="100%" stopColor="#FFFFFF" stopOpacity={0} />
                    </linearGradient>

                    <linearGradient id="colorPv" x1="0" y1="0" x2="1" y2="100%">
                        <stop offset="0%" stopColor="#82ca9d" stopOpacity={0} />
                        <stop offset="100%" stopColor="#82ca9d" stopOpacity={0} />
                    </linearGradient>
                </defs>

                <CartesianGrid opacity={0.3} vertical={false} horizontal={true} />

                <XAxis
                    dataKey="index"
                    axisLine={true}
                    interval={"preserveStartEnd"}
                    tick={{ fill: '#B8B9C5' }}
                    type="number"
                    tickLine={false}
                    domain={[0, geodata.geodataArray.length - 1]}
                    ticks={generateChartTicks(geodata.totalLength)}
                    tickSize={15}
                    tickFormatter={item => {
                        return `${(geodata.geodataArray[item].dist).toFixed(1).toString()}\u00A0km`
                    }}
                    style={{
                        fontSize: 12
                    }}
                />


                <YAxis
                    type="number"
                    domain={['auto', 'auto']}
                    style={{
                        fontSize: 12,
                    }}
                    dataKey={"elevation"}
                    tick={{ fill: '#B8B9C5' }}
                    axisLine={true}
                    tickLine={false}
                    tickCount={5}
                    tickFormatter={number => `${number}\u00A0m`}
                />

                <Tooltip
                    wrapperStyle={{ visibility: 'visible', zIndex: -1000 }}
                    position={{ y: isMobileScreen() ? -3 : -20, 
                        x: isMobileScreen() ?  -34.321 - (-0.09255404/-0.002668112)*(1 - Math.exp(+0.002668112*width)) : 80
                    }}
                    itemStyle={{ alignSelf: 'center', padding: '1.2% .5%' }}
                    cursor={chartIndex < 0 ? true : false}
                    children={null}
                    content={<StatsTooltip
                        updateChartLastIndex={setChartLastIndex}
                        chartLastIndex={chartLastIndex}
                        chartIndex={chartIndex}
                        setChartIndex={setChartIndex}/>} 
                        />



                {
                    renderRefLines().map((line) => {
                        return line;
                    })
                }


                {
                    // Rendering the points of interest as reference dots on the chart
                    poiIn.map(p => {
                        return (
                            <ReferenceDot key={p['@id']} x={p.point[0]} y={!isMobileScreen() ? p.point[1] : maximumAltitude}
                                shape={<CustomReferenceDot cx={p.point[0]} cy={!isMobileScreen() ? p.point[1] : maximumAltitude} img={p.img} subname={p.subname} iconUrl={p.iconUrl} name={p.name} setCurrentPoi={setCurrentPoi} alt={alt} />}
                            />
                        )
                    })
                }

                {
                    isMobileScreen() ? poiIn.map(p => {
                        return (
                            <ReferenceLine
                                x={p.point[0]}
                                stroke="#30B2D1"
                            />
                        )
                    }) : null
                }

                <ReferenceDot
                    key="startPoint"
                    height={30}
                    width={30}
                    x={0}
                    y={!isMobileScreen() ? geodata.geodataArray[0].elevation : maximumAltitude}
                    shape={<CustomReferenceDot name={language === "en" ? "Start" : "Début"}
                        img={startIconHref}
                        cx={0}
                        geodata={!isMobileScreen() ? geodata.geodataArray[0].elevation : maximumAltitude}
                        subname={language === "en" ? "Start" : "Début"} />} />


                <ReferenceDot
                    key="endPoint"
                    height={30}
                    x={geodata.geodataArray.length - 1}
                    y={!isMobileScreen() ? geodata.geodataArray[geodata.geodataArray.length - 1].elevation : maximumAltitude}
                    shape={<CustomReferenceDot name={language === "en" ? "End" : "Fin"}
                        cx={geodata.geodataArray.length - 1}
                        cy={!isMobileScreen() ? geodata.geodataArray[geodata.geodataArray.length - 1].elevation : maximumAltitude}
                        img={endIconHref}
                        subname={language === "en" ? "End" : "Fin"} />} />


                <Area type="monotone" dataKey="elevation" stroke="url(#line)" fillOpacity={1} strokeWidth={2} fill="url(#color)"
                    activeDot={chartIndex < 0 ? { ...dotChart } : { r: 0 }}
                    dot={dotFromMap}
                    onTouchStart={() => {
                        setChartIndex(-1);
                    }}
                />
            </AreaChart>
        </ResponsiveContainer >
    );

}
export default GraphicChart;