import React, { useState, useContext, useRef, useEffect, Suspense } from 'react';
import { MAPBOX_KEY } from '../config/config'
import ReactMapGL, { Marker, Popup, NavigationControl, Source, Layer, FlyToInterpolator, WebMercatorViewport } from 'react-map-gl';
import PointOfInterest from '../Modals/Poi'
import { API_URL } from '../config/config'
import RightButtons from './RightButtons'
import LeftButtons from './LeftButtons '
import { RouteContext } from '../config/RouteContext'
import { ReactComponent as StartIcon } from '../assets/start_icon.svg'
import { ReactComponent as EndIcon } from '../assets/end_icon.svg';
import { isMobileScreen, isSmallHeight, mapWidgetElement } from '../utils/responsive';

import mapboxgl from 'mapbox-gl';
import { getMapBounds, isPointOnLine } from '../utils/calculations';
import { CurrentMarkerIcon } from './CurrentMarkerIcon';
import { updateMarkerFromTooltip } from '../utils/markerCoordinates';

const responsiveZoom = () => {

    const websiteWidth = mapWidgetElement().clientWidth;

    if (websiteWidth < 700) {
        return 10.3;
    }

    if (websiteWidth < 400) {
        return 10.2;
    }
    if (websiteWidth < 500) {
        return 10.4
    }
    if (websiteWidth < 600) {
        return 10.6
    }
    if (websiteWidth < 800) {
        return 10.8
    }
    if (websiteWidth < 1000) {
        return 11
    }
    return 11.5;
}



try {
    function workerCros(url) {
        const iss = "importScripts('" + url + "');";
        return URL.createObjectURL(new Blob([iss]));
    }

    const workerUrl = workerCros(new URL("https://api.mapbox.com/mapbox-gl-js/v2.6.1/mapbox-gl-csp-worker.js", window.location).href);
    if (workerUrl !== null) {
        mapboxgl.workerUrl = workerUrl;
    }
} catch (error) {
    console.log(error);
}

const Mapp = (props) => {

    const mapContainer = useRef();
    const { currentPoi, setCurrentPoi, routes } = useContext(RouteContext);

    const currentMarkerRef = useRef();

    const [viewport, setViewport] = useState({
        latitude: routes.startingPoint.latitude,
        longitude: routes.startingPoint.longitude,
        zoom: responsiveZoom(),
        interactive: false,
        pitch: 0,
    });



    const [currentPlace, setCurrentPlace] = useState(null)
    const [mapS, setMapS] = useState('mapbox://styles/guidos-cloud/ckx6a7s2g7lyl15qa6fewqv8k')
    const [elevation, setElevation] = useState(0);
    const [mobileModalOpen, setMobileModalOpen] = useState(false);
    const [isButtonActive, setButtonActive] = useState(false);


    const handleMarkerClick = name => {
        setCurrentPlace(name);
        setMobileModalOpen(true);
    }

    const changeMapStyle = (buttonIndex) => {

        setButtonActive(false);
        if (buttonIndex === 0) {
            if (elevation === 1.5) {
                setMapS(mapS);
                mapContainer.current.getMap(0).setTerrain({ source: "mapbox-dem", exaggeration: 0 });
                setElevation(0);
                setViewport({
                    ...viewport,
                    pitch: 0,
                    transitionDuration: 2000,
                    transitionInterpolator: new FlyToInterpolator()
                })
            }
            else {
                setMapS(mapS);
                mapContainer.current.getMap(0).setTerrain({ source: "mapbox-dem", exaggeration: 1.5 });
                setElevation(1.5);
                setViewport({
                    ...viewport,
                    pitch: 60,
                    transitionDuration: 2000,
                    transitionInterpolator: new FlyToInterpolator()
                })
            }

        }
        else if (buttonIndex === 1) {
            mapContainer.current.getMap(0).on('styledata', (props) => {
                mapContainer.current.getMap(0).setTerrain({ source: "mapbox-dem", exaggeration: elevation });
            });
            if (mapS === 'mapbox://styles/guidos-cloud/ckx6a7s2g7lyl15qa6fewqv8k') {
                setMapS('mapbox://styles/mapbox/satellite-streets-v11');
            }
            else {
                setMapS('mapbox://styles/guidos-cloud/ckx6a7s2g7lyl15qa6fewqv8k')
            }
        }

    }

    useEffect(() => {
        //If the currentPoi context was changed, we set the currentPlace state to the name provided by the GraphicChart Component
        if (currentPoi.length > 0) {
            setCurrentPlace(currentPoi);
            setMobileModalOpen(true);
        }
        else {
            setCurrentPlace(null);
            setMobileModalOpen(false);
        }
    }, [currentPoi])// eslint-disable-line react-hooks/exhaustive-deps

    const ResponsivePoiPopups = (p) => {
        if (p.name === currentPlace) {
            if (!isMobileScreen()) {
                return (
                    <Popup latitude={p.latitude} longitude={p.longitude} closeButton={false} closeOnClick={true} anchor="top">
                        <PointOfInterest name={p.name} subname={p.type.name} description={p.description} image={p.image.contentUrl} icon={p.type.mapIcon.contentUrl}
                            close={() => {
                                setCurrentPlace(null);
                                setCurrentPoi("");
                            }} />
                    </Popup>
                )
            }
            return (

                <div >
                    {mobileModalOpen && (
                        <div style={{ position: 'absolute', maxHeight: '90%', overflow: 'auto', top: 10, left: (mapWidgetElement().clientWidth - 300) / 2, width: 310, zIndex: 930, }}>
                            <PointOfInterest name={p.name} subname={p.type.name} description={p.description} image={p.image.contentUrl} icon={p.type.mapIcon.contentUrl}
                                close={() => {
                                    setMobileModalOpen(false)
                                    setCurrentPlace(null);
                                    setCurrentPoi("");
                                }} />
                        </div>
                    )}
                </div>
            )
        }
        return null;
    }



    return (
        <React.Fragment>

            <ReactMapGL
                ref={mapContainer}
                {...viewport} width={"100%"} mapboxApiAccessToken={MAPBOX_KEY}
                onViewportChange={nextViewport => {
                    if (!isButtonActive) {
                        setViewport({ ...nextViewport })
                    }
                }}
                onMouseMove={(event) => {
                    if (!event.hasOwnProperty("changedPointers")) {
                        const linePoint = isPointOnLine(event.lngLat);
                        currentMarkerRef.current.updateMarker(linePoint.coordinates);
                        updateMarkerFromTooltip(linePoint.index);
                    }
                }}
                mapStyle={mapS}
                height="100%"
                onLoad={() => {

                    const bounds = getMapBounds(routes);
                    const { longitude, latitude, zoom } = new WebMercatorViewport(viewport)
                        .fitBounds(bounds, { padding: { top: 10, bottom: (window.innerHeight - mapWidgetElement().clientHeight + isSmallHeight() || isMobileScreen() ? 200 : 300), left: 30, right: 80 } });
                    setViewport({
                        ...viewport,
                        latitude: latitude,
                        longitude: longitude,
                        zoom: zoom,
                        transitionDuration: 500,
                        transitionInterpolator: new FlyToInterpolator()
                    })
                }}
            >
                <Suspense fallback="Loading...">
                    <RightButtons mapStyle={changeMapStyle} currentMapStyle={mapS} currentElevation={elevation} setButtonActive={setButtonActive} >
                        {!isMobileScreen() && <NavigationControl showCompass={false} />}

                    </RightButtons>


                    <LeftButtons setButtonActive={setButtonActive} />
                </Suspense>


                <CurrentMarkerIcon ref={currentMarkerRef} />

                {/* STARTING POINT */}
                <Marker latitude={routes.geoJson.coordinates[0][1]} longitude={routes.geoJson.coordinates[0][0]} offsetLeft={-20} offsetTop={-10}>
                    <StartIcon style={{ width: "45px", maxWidth: "45px", height: 70, transform: "translate3d(0,-60%,0)" }} />
                </Marker>

                {/* ENDING POINT */}
                <Marker latitude={routes.geoJson.coordinates[routes.geoJson.coordinates.length - 1][1]} longitude={routes.geoJson.coordinates[routes.geoJson.coordinates.length - 1][0]} offsetLeft={-20} offsetTop={-10}>
                    <EndIcon style={{ width: "45px", maxWidth: "45px", height: 70, transform: "translate3d(-5px,-60%,0)" }} />
                </Marker>


                {
                    routes && routes.pointsOfInterest.map(p => (
                        <React.Fragment key={p["@id"]}>
                            <Marker latitude={p.latitude} longitude={p.longitude} offsetLeft={-20} offsetTop={-10}>
                                <img src={`${API_URL}${p.type.mapIcon.contentUrl}`} alt='point-of-interest-marker' style={{ width: "45px", maxWidth: "45px", transform: "translate3d(0,-50%,0)", zIndex: -1, cursor: "pointer" }} onClick={() => handleMarkerClick(p.name)} />
                            </Marker>
                            {//Mapping through the points of interest, if any of them matches the name of current place
                                //we render the POI popup
                                ResponsivePoiPopups(p)
                            }
                        </React.Fragment>
                    ))
                }

                <Source id='routeSource' type='geojson' data={routes.geoJson} />
                <Layer
                    id='routeLayer'
                    type='line'
                    source='routeSource'
                    layout={{
                        'line-join': 'round',
                        'line-cap': 'round',
                        'line-sort-key': -9999,
                    }}
                    paint={{
                        'line-color': '#051B45',
                        'line-width': 6,
                    }}
                />

                <Source id='mapbox-dem' type='raster-dem' url='mapbox://mapbox.mapbox-terrain-dem-v1' tileSize={512} maxzoom={16} />
                <Layer
                    id='sky'
                    type='sky'
                    paint={{
                        "sky-type": "atmosphere",
                        "sky-atmosphere-sun": [0.0, 90.0],
                        "sky-atmosphere-sun-intensity": 15,
                    }}
                />
            </ReactMapGL >
        </React.Fragment>
    )
};

export default Mapp;