import React, { useRef } from 'react';
import { Popup } from 'reactjs-popup';
import { isMobileScreen } from '../utils/responsive';
import { backgroundColorStyle } from '../utils/responsive';



const isStartEnd = (name) => {
    return name === "Start" || name === "End" || name === "Fin" || name === "Début";
}

const isStart = (name) => {
    return name === "Start" || name === "Début"
}

const borderRadiusValue = (name) => {
    if (isStartEnd(name) && isStart(name)) {
        return '10px 10px 10px 0px'
    }
    else if (isStartEnd(name)) {
        return '10px 10px 0px 10px'
    }
    else return '20px';
}



const CustomReferenceDot = props => {
    const popupRef = useRef();
    const { cx, cy, img, name, subname } = props;
    let currentColor = backgroundColorStyle(subname);

    return (

        <Popup
            on={'hover'}
            ref={isStartEnd(name) ? popupRef : null}
            position={'top center'}
            arrow={false}
            offsetY={10}
            keepTooltipInside={true}
            closeOnDocumentClick={isStartEnd(name) ? false : true}
            closeOnEscape={isStartEnd(name) ? false : true}
            trigger={
        <image x={cx - 20} y={cy - 47} width={40} height={40} xlinkHref={img} alt="" style={{ cursor: name === null ? 'default' : 'pointer', zIndex: 970 }}
            onClick={() => {
                //On clicking the icon, we set the currentPoi context to the name of the clicked point of interest
                if (!isStartEnd(name)) {
                    props.setCurrentPoi(name);
                }
            }} />}
        >

            <div style={{
                backgroundColor: currentColor,
                color: 'white',
                padding: '4px 10px 6px 10px',
                margin: 0,
                borderRadius: borderRadiusValue(name),
                zIndex: isStartEnd(name) ? 910 : 911,
            }}>
                <p style={{ lineHeight: 1, fontSize: isMobileScreen() ? 12 : 14, verticalAlign: 'center', fontWeight: 500, margin: '0px 0px' }}>{name}</p>
            </div>
        </Popup>
    );
};

export default CustomReferenceDot;