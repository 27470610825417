import * as turf from '@turf/turf';

let altitudeArray = [];
let geodataArray = [];
let totalLength = -1;
let ticks = [];
let minLng = 500;
let minLat = 500; 
let maxLng = -500;
let maxLat = -500;
let bounds = [];

let geodataLineString = null;

export const resetArrays = () => {
    altitudeArray = [];
    geodataArray = [];
    totalLength = -1;
    ticks = [];
    minLng = 500;
    minLat = 500;
    maxLng = -500;
    maxLat = -500;
}

export const downloadGPX = async (name, gpxUrl) => {
    await fetch(gpxUrl)
        .then(response => {
            return response.blob();
        })
        .then((response) => {
            const url = window.URL.createObjectURL(response);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute("download", `${name}.gpx`);
            link.click();
        })
}

export const calculateTotalAltitude = (routes) => {
    if (altitudeArray.length > 0) {
        return altitudeArray;
    }
    altitudeArray = routes.geoJson.coordinates.map((coordinate, index) => {
        return {
            up: coordinate[2],
            down: 0
        };
    });
    return altitudeArray;
}

export const isPointOnLine = (point) => {
    const turfPoint = turf.point(point);
    if (turf.booleanPointOnLine(turfPoint, geodataLineString, { epsilon: 0.0000001, ignoreEndVertices: true })) {
        const linePoint = turf.pointOnLine(geodataLineString, turfPoint);
        return {
            coordinates: linePoint.geometry.coordinates,
            index: linePoint.properties.index,
        }
    }
    else {
        return {
            coordinates: [],
            index: -1,
        };
    }
}


export function computeGeoDataArray(routes) {
    if (geodataArray.length > 0) {
        return {
            totalLength: totalLength,
            geodataArray: geodataArray
        }
    }
    const totalRouteTime = routes.routeActivities[0].duration;
    const line = turf.lineString(routes.geoJson.coordinates);
    geodataLineString = line;
    totalLength = turf.lineDistance(line, 'kilometers');
    const geoSlice = [routes.geoJson.coordinates[0], routes.geoJson.coordinates[1]];
    routes.geoJson.coordinates.map((data, index) => {
        if (index > 1) {
            const line = turf.lineString(geoSlice);
            geoSlice.push(routes.geoJson.coordinates[index]);
            const distance = turf.lineDistance(line);
            const currentTime = (distance * totalRouteTime) / totalLength;
            geodataArray.push({
                lng: data[0],
                lat: data[1],
                elevation: data[2],
                dist: distance,
                time: currentTime,
                index: index
            })

        }
        else {
            geodataArray.push({
                lng: data[0],
                lat: data[1],
                elevation: data[2],
                dist: 0,
                time: 0,
                index: index,
            });
        }
        return null;
    });

    return {
        totalLength: totalLength,
        geodataArray: geodataArray
    }
}

export const generateChartTicks = () => {
    

    let frequencyArray = []
    for (let i = 0; i <= totalLength; i ++) {
        frequencyArray.push(0);
    }

    if (geodataArray !== undefined) {
        ticks = [0];
        const geoLength = geodataArray.length;
        for (let i = 1; i < geoLength; i ++) {
            const km = Math.floor(geodataArray[i].dist);
            if (frequencyArray[km] === 0) {
                ticks.push(i);
                frequencyArray[km] = 1;
            }
        }

        if (Math.floor(geodataArray[geoLength-1].dist) !== geodataArray[geoLength-1].dist) {
            ticks.push(geoLength-1);
        }
    }

    return ticks;
}

export const getMapBounds = (routes) => {
    
    if (bounds.length > 0) {
        return bounds;
    }
    
    const {coordinates} = routes.geoJson;

    
    coordinates.map(c => {
        if (c[0] > maxLng) {
            maxLng = c[0];
        }

        if (c[0] < minLng) {
            minLng = c[0];
        }

        if (c[1] > maxLat) {
            maxLat = c[1];
        }

        if (c[1] < minLat) {
            minLat = c[1];
        }
        return null;
    })

    bounds = [[maxLng, minLat], [minLng, maxLat]]
    return bounds;

}

export const getStartingPoints = () => {
    return {
        latitude: (minLat + maxLat) / 2,
        longitude: (minLng + maxLng) / 2
    }
}

export function fnBrowserDetect(){
                 
    let userAgent = navigator.userAgent;
    let browserName;
    
    if(userAgent.match(/chrome|chromium|crios/i)){
        browserName = "chrome";
      }else if(userAgent.match(/firefox|fxios/i)){
        browserName = "firefox";
      }  else if(userAgent.match(/safari/i)){
        browserName = "safari";
      }else if(userAgent.match(/opr\//i)){
        browserName = "opera";
      } else if(userAgent.match(/edg/i)){
        browserName = "edge";
      }else{
        browserName="No browser detection";
      }
      
      return browserName;
}