import i18n from 'i18next'
import HttpApi from 'i18next-http-backend'
import {
    initReactI18next
} from 'react-i18next';
import {TRANSLATE_URL} from '../config/config';

i18n
    .use(HttpApi)
    .use(initReactI18next)
    .init({
        lng: 'fr',
        backend: {
            loadPath: TRANSLATE_URL + "{{lng}}.json",
        },
        fallbackLng: 'fr',
        keySeparator: false,
        interpolation: {
            escapeValue: true,
        },
        react: {
            wait: true
        }
    })

export default i18n