import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Marker } from 'react-map-gl';
import { ReactComponent as MarkerIcon } from '../assets/currentPosition.svg'
import {setMapUpdateFunction} from '../utils/markerCoordinates';

export const CurrentMarkerIcon = forwardRef((props, ref) => {

    const [currentMarker, setCurrentMarker] = useState([]);


    const updateCurrentMarker = (newValue) => {
        if (currentMarker !== newValue) {
            setCurrentMarker(newValue);
        }
    }

    useEffect(() => {
        //The function from markerCoordinates.js is set here
        setMapUpdateFunction(updateCurrentMarker);
    }) 

    useImperativeHandle(ref, () => ({
        updateMarker(newMarker) {
            setCurrentMarker(newMarker);
        }
    }))

    return (
        currentMarker.length > 0 ? (
            <Marker latitude={currentMarker[1]} longitude={currentMarker[0]} offsetLeft={-20} offsetTop={-10}>
                <MarkerIcon />
            </Marker>
        ) : null

    )

})