import React, { useContext, useRef } from 'react'

import { API_URL } from '../config/config'
import { RouteContext } from '../config/RouteContext'
import { MoreInfoDiv } from './StyledComponents'
import styled from '../Style/MoreInfo.module.css'

import { ReactComponent as Gpx } from '../assets/MoreInfo/gpxicon.svg'
import { ReactComponent as Exit } from '../assets/MoreInfo/exit-more-info.svg'


import { calculateTotalAltitude, computeGeoDataArray, downloadGPX } from '../utils/calculations';

import { ReactComponent as Clock } from '../assets/MoreInfo/time.svg'
import { ReactComponent as Distance } from '../assets/MoreInfo/distance.svg'
import { ReactComponent as Up } from '../assets/MoreInfo/updist.svg'
import { ReactComponent as Down } from '../assets/MoreInfo/downdist.svg'
import { isMobileScreen } from '../utils/responsive';
import {useTranslation} from 'react-i18next';


const levelBackgroundColor = (name) => {
    if (name === "Beginner" || name === "Débutant") {
        return '#76A2CC';
    }
    if (name === "Novice") {
        return '#00AB84';
    }
    if (name === "Expert") {
        return '#000000';
    }
    if (name === "Advanced" || name === "Avancé") {
        return '#FE0008';
    }
    return '#0097d7';
}

const ascent = (route) => route.metaData.find(meta => meta['name'] == 'Dénivelé positif')['value'];
const descent = (route) => route.metaData.find(meta => meta['name'] == 'Dénivelé négatif')['value'];

const MoreInfo = (props) => {
    const divRef = useRef();
    const { routes } = useContext(RouteContext);
    const altitudes = calculateTotalAltitude(routes);
    const { totalLength } = computeGeoDataArray(routes);
    const { t } = useTranslation();

    return (
        <div ref={divRef} className={styled['card-more-info']} style={{zIndex: 920, width: isMobileScreen() ? '90%' : 'default'}} onWheel={(event) => {
            divRef.current.scrollTo({
                top: divRef.current.scrollTop + event.deltaY,
                behaviour: 'smooth'
            })
        }} onMouseOver={() =>{
            props.setButtonActive(true);
        }} onMouseOut={() => props.setButtonActive(false)}>
            <div>
                <MoreInfoDiv style={{ marginBottom: isMobileScreen() ? '10px' : '25px' }} imageUrl={`${API_URL}${routes.images[0].contentUrl}`}>
                    <button className={styled["back-more-info"]} onClick={props.close}>
                        <Exit fill="#000000" cursor="pointer" />
                    </button>
                    <div className={styled["etq-more-info"]}>
                        <div className={styled["logo-more-info"]}>
                            <img src={`${API_URL}${routes.organization.logo.contentUrl}`} height={20} alt="Provider logo" />
                        </div>
                        <div className={styled["level-more-info"]} style={{ backgroundColor: levelBackgroundColor(routes.routeActivities[0].level.name) }}>{routes.routeActivities[0].level.name}</div>
                    </div>
                </MoreInfoDiv>

                <div className={styled["icon-text-more-info"]}>
                    <div className={`${styled["stats-more-info"]} ${styled["border-more-info"]} ${styled["left-more-info"]}`}>
                        <div className={styled["icon-more-info"]}>
                            <Clock height={isMobileScreen() ? 15 : 25} fill="#00AB84" style={{color: '#00AB84'}} />
                        </div >
                        <div className={styled["type-more-info"]}>{routes.routeActivities[0].duration} min</div>
                    </div >
                    <div className={`${styled["stats-more-info"]} ${styled["border-more-info"]} ${styled["left-more-info"]}`}>
                        <div className={styled["icon-more-info"]}>

                            <Distance height={isMobileScreen() ? 20 : 25} width={30} style={{ marginBottom: -5, color: '#00AB84' }} fill="#00AB84" />

                        </div >
                        <div className={styled["type-more-info"]}>{totalLength.toFixed(1)} km</div>
                    </div >
                    <div className={`${styled["stats-more-info"]} ${styled["border-more-info"]} ${styled["left-more-info"]}`} >
                        <div className={styled["icon-more-info"]}>

                            <Up height={isMobileScreen() ? 15 : 25} style={{color: '#00AB84'}} fill="#00AB84" />

                        </div>
                        <div className={styled["type-more-info"]}>{ascent(routes)}</div>

                    </div>
                    <div className={styled["stats-more-info"]}>
                        <div className={styled["icon-more-info"]}>
                            <Down height={isMobileScreen() ? 15 : 25} fill="#00AB84" style={{color: '#00AB84'}} />
                        </div>
                        <div className={styled["type-more-info"]}>{descent(routes)}</div>
                    </div >
                </div >

                <div className={`${styled["informations-more-info"]} ${styled["text-more-info"]}`}>
                    <div className={styled["heading-more-info"]}>
                        <h1>{routes.name}</h1>
                        <h2>{routes.location}</h2>
                    </div>
                    <p className={styled["p-more-info"]}>
                        {routes.description}
                    </p>
                </div>
                <div className={styled["buttons-more-info"]}>
                    <button className={styled["purple-more-info"]} onClick={() => downloadGPX(`${routes.name}`, `${API_URL}${routes.gpx.contentUrl}`)}>
                        <Gpx fill={'#00AB84'} style={{color: '#00AB84'}} />
                        {t('gpxMoreInfoButton')}
                    </button>
                </div>
            </div>
        </div >
    )

}

export default MoreInfo;

