import React, { useEffect, useRef, useState } from 'react';
import Mapp from '../UI/Mapp';
import axios from 'axios';
import { RouteContext } from '../config/RouteContext';
import { ReactComponent as LoadingSVG } from '../assets/loader.svg'
import { ReactComponent as GuidosLogo } from '../assets/Logo_Guidos.svg';
import { mapWidgetElement } from '../utils/responsive';
import { ElevationProfile } from '../Profiler/ElevationProfile';


import '../Style/Card.module.css'
import { computeGeoDataArray, resetArrays } from '../utils/calculations';

import i18n from "i18next";
import '../translation/i18n';


let languageFromTag = false;


const Container = () => {

    const elevationRef = useRef();
    const [routes, _setRoutes] = useState([])
    const setRoutes = (routes) => {
        _setRoutes(routes);
        setGeoData(computeGeoDataArray(routes));
    };
    const [geodata, setGeoData] = useState({});
    const [isLoading, setLoading] = useState(true);
    const [currentMarker, setCurrentMarker] = useState([]);
    const [currentPoi, setCurrentPoi] = useState("");
    const [language, setLanguage] = useState('fr');


    useEffect(() => {

        setLoading(true);
        resetArrays();
        i18n.changeLanguage(language);
        const tag = mapWidgetElement();
        const queryParams = new URLSearchParams(window.location.search);

        if (tag === null || (tag.getAttribute("fullscreen") !== undefined && tag.getAttribute("fullscreen") !== null)) {
            document.body.style.position = "fixed";
        }

        if (tag !== null && tag.getAttribute("language") !== undefined && tag.getAttribute("language") !== null && languageFromTag === false) {
            setLanguage(tag.getAttribute("language"));
            languageFromTag = true;
        }
        else if (queryParams.get("language") !== null) {
            setLanguage(queryParams.get("language").toString());
            languageFromTag = true;
        }
        const headers = {
            "Accept-Language": language
        }

        if (tag !== null && tag.getAttribute("key") !== undefined && tag.getAttribute("key") !== null) {
            headers['X-Auth-Key'] = tag.getAttribute("key");
        }

        if (tag !== null) {
            axios.get(`https://api.guidos.fun/routes/${tag.getAttribute("route")}`, { headers: headers })
                .then(async res => {
                    // console.log(res.data);
                    setRoutes(res.data);
                    setLoading(false);
                })
        }
        else if (queryParams.get("route") !== null) {
            axios.get(`https://api.guidos.fun/routes/${queryParams.get("route").toString()}`, { headers: headers })
                .then(async res => {
                    // console.log(res.data);
                    setRoutes(res.data);
                    setLoading(false);
                })
        }
        else {
            axios.get('https://api.guidos.fun/routes/6b3adbcb-9fec-4e89-bf3d-61e5b5770d10', { headers: headers })
                .then(async res => {
                    console.log(res.data);
                    setRoutes(res.data);
                    setLoading(false);
                })
                .catch(error => {
                    console.error(error);
                })
        }

    }, [language]);


    if (isLoading) {
        return (
            <div style={{ position: 'relative', display: 'flex', alignItems: 'center', alignContent: 'center', justifyContent: 'center', height: '100%', width: '100%' }}>
                <LoadingSVG width={100} fill='#212548' style={{ color: '#212548' }} />
            </div>
        )
    }

    return (

        <RouteContext.Provider value={{ routes, geodata, currentMarker, setCurrentMarker, currentPoi, setCurrentPoi, language, setLanguage }}>
            <Mapp elevationRef={elevationRef} />
            <ElevationProfile ref={elevationRef} />
        </RouteContext.Provider>
    )
}

export default Container;