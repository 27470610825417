/**
 * Author: Rares Liscan
 */

import { ReactComponent as Clock } from '../assets/MoreInfo/time.svg'
import { ReactComponent as Distance } from '../assets/MoreInfo/distance.svg'
import { ReactComponent as Up } from '../assets/MoreInfo/updist.svg'
import { ReactComponent as Down } from '../assets/MoreInfo/downdist.svg';
import { calculateTotalAltitude, computeGeoDataArray } from '../utils/calculations';

import { isMobileScreen } from '../utils/responsive';
import styles from '../Style/Tooltip.module.css';
import { useContext } from 'react';
import { RouteContext } from '../config/RouteContext';
import { updateMarkerFromMap} from '../utils/markerCoordinates';
let geodata = [];

/**
 * @param {updateChartIndex} function updates the chart index in order to keep the dot active 
 * @returns The tooltip with its current values, obtained from user mouse hover / mobile tap
 */

let lastChartIndex = -1;
let alt = [];

const StatsTooltip = (props) => {

    const {routes} = useContext(RouteContext);

    if (geodata.length === 0) {
        geodata = computeGeoDataArray(routes).geodataArray;
    }

    const { active, chartLastIndex, chartIndex, updateChartLastIndex } = props;
    if (alt.length === 0) {
        alt = calculateTotalAltitude(routes);
    }
    const iconStyle = {
        height: 20, width: 20, marginRight: 5, color: '#ffffff', fill: 'white'
    };


    if (active && chartIndex < 0) {
        if (chartLastIndex > 0) {
            updateChartLastIndex(-2);
        }
        if (props.label !== undefined && props.label > 1) {
            lastChartIndex = props.label;
            const mark = [routes.geoJson.coordinates[props.label - 1][0], routes.geoJson.coordinates[props.label - 1][1]];
            updateMarkerFromMap(mark);
        }
        return (
            <div className={styles["border-tooltip"]} style={{borderWidth: isMobileScreen() ? 0 : 1}}>

                <div className={styles["btn-tooltip"]}>

                    <Clock fill="white" style={iconStyle} />
                    <a href="!#" className={styles["bttn-tooltip"]}>
                        {props.label > 1 ? geodata[props.label - 1].time.toFixed(0) : 0} min
                    </a>
                </div>

                <div className={styles["btn-tooltip"]}>
                    <Distance fill="#FFFFFF" style={iconStyle} />
                    <a href="!#" className={styles["bttn-tooltip"]}>{' '}{props.label > 1 ? geodata[props.label - 1].dist.toFixed(1) : 0} km</a>
                </div>

                <div className={styles["btn-tooltip"]}>
                    <Up fill="white" style={iconStyle} />
                    <a href="!#" className={styles["bttn-tooltip"]}>{' '}{props.label > 1 ? alt[props.label - 1].up.toFixed(0) : 0} m</a>
                </div>

            </div>
        );
    } 
    else if (alt !== undefined && chartIndex > 0) {
        lastChartIndex = chartIndex;
        if (chartLastIndex > 0) {
            updateChartLastIndex(-2);
        }
        return (
            <div className={styles["border-tooltip"]} style={{borderWidth: isMobileScreen() ? 0 : 1}}>

                <div className={styles["btn-tooltip"]}>

                    <Clock fill="white" style={iconStyle} />
                    <a href="!#" className={styles["bttn-tooltip"]}>
                        {chartIndex > 1 ? geodata[chartIndex - 1].time.toFixed(0) : 0} min
                    </a>
                </div>

                <div className={styles["btn-tooltip"]}>
                    <Distance fill="#FFFFFF" style={iconStyle} />
                    <a href="!#" className={styles["bttn-tooltip"]}>{' '}{chartIndex > 1 ? geodata[chartIndex - 1].dist.toFixed(1) : 0} km</a>
                </div>

                <div className={styles["btn-tooltip"]}>
                    <Up fill="white" style={iconStyle} />
                    <a href="!#" className={styles["bttn-tooltip"]}>{' '}{chartIndex > 1 ? alt[chartIndex - 1].up.toFixed(0) : 0} m</a>
                </div>

            </div>
        );
    }
    else if (alt !== undefined && chartLastIndex > 0) {
        return (
            <div className={styles["border-tooltip"]} style={{borderWidth: isMobileScreen() ? 0 : 1}}>

                <div className={styles["btn-tooltip"]}>

                    <Clock fill="white" style={iconStyle} />
                    <a href="!#" className={styles["bttn-tooltip"]}>
                        {chartLastIndex > 1 ? geodata[chartLastIndex - 1].time.toFixed(0) : 0} min
                    </a>
                </div>

                <div className={styles["btn-tooltip"]}>
                    <Distance fill="#FFFFFF" style={iconStyle} />
                    <a href="!#" className={styles["bttn-tooltip"]}>{' '}{chartLastIndex > 1 ? geodata[chartLastIndex - 1].dist.toFixed(1) : 0} km</a>
                </div>

                <div className={styles["btn-tooltip"]}>
                    <Up fill="white" style={iconStyle} />
                    <a href="!#" className={styles["bttn-tooltip"]}>{' '}{chartLastIndex > 1 ? alt[chartLastIndex - 1].up.toFixed(0) : 0} m</a>
                </div>

            </div>
        );
    }
    else if (alt !== undefined) {
        if (chartLastIndex !== lastChartIndex && !isMobileScreen()) {
            updateChartLastIndex(lastChartIndex);
        }
        return (
            <div className={styles["border-tooltip"]} style={{borderWidth: isMobileScreen() ? 0 : 1}}>

                <div className={styles["btn-tooltip"]}>

                    <Clock fill="white" style={iconStyle} />
                    <a href="!#" className={styles["bttn-tooltip"]}> {routes.routeActivities[0].duration} min</a>
                </div>

                <div className={styles["btn-tooltip"]}>
                    <Distance fill="#FFFFFF" style={iconStyle} />
                    <a href="!#" className={styles["bttn-tooltip"]}>{' '} {computeGeoDataArray(routes).totalLength.toFixed(1)} km</a>
                </div>

                <div className={styles["btn-tooltip"]}>
                    <Up fill="white" style={iconStyle} />
                    <a href="!#" className={styles["bttn-tooltip"]}>{' '} {alt[alt.length - 1].up.toFixed(0)} m</a>
                </div>

            </div>
        );
    }
    return null;
};



export default StatsTooltip;