/**
 * @author Rares Liscan
 * 
 * @function updateMarkerFromMap - updates the green dot marker on map hover
 * @function updateMarkerFromTooltip - updates the green dot on tooltip map hover
 * 
 * Both function have to be set from the corresponding js files. Their purpose is to update the state from Tooltip.js and CurrentMarkerIcon.js from all components
 * 
 */


export let updateMarkerFromTooltip;
export let updateMarkerFromMap;

export const setMapUpdateFunction = (func) => {
    updateMarkerFromMap = func;
}

export const setTooltipUpdateFunction = (func) => {
    updateMarkerFromTooltip = func;
}