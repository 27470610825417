import rightStyles from '../Style/RightButtons.module.css'
import { ReactComponent as GpxD } from '../assets/gpx-download.svg'
import { ReactComponent as Mobile } from '../assets/mobile.svg'
import { useContext, useState, useEffect, Suspense } from 'react'
import { RouteContext } from '../config/RouteContext'
import { downloadGPX } from '../utils/calculations';
import { API_URL } from '../config/config';
import { Close } from '@material-ui/icons'
import styled from '../Style/MoreInfo.module.css'
import { isMobileScreen, popupMobileDevice } from '../utils/responsive'
import Select from 'react-select'
import { ReactComponent as FranceFlag } from '../assets/france.svg';
import { ReactComponent as US } from '../assets/us.svg';
import { useTranslation } from "react-i18next";

const options = [
    { value: 'en', label: <US width={22} height={25} style={{ margin: 0, padding: 0, display: 'flex', alignItems: 'center', borderRadius: '10px' }} /> },
    { value: 'fr', label: <FranceFlag width={22} height={25} style={{ margin: 0, padding: 0, display: 'flex', alignItems: 'center', borderRadius: '10px' }} /> },
]


const RightButtons = (props) => {

    const [popupOpen, setPopupOpen] = useState(false)
    const { language, setLanguage, routes  } = useContext(RouteContext);
    const [selectIndex, setSelectIndex] = useState(1);
    const { t } = useTranslation();

    useEffect(() => {
        if (language === 'en') {
            setSelectIndex(0);
        }
        else {
            setSelectIndex(1);
        }
    }, [language]);

    return (
        <Suspense fallback="Loading...">
            <div className={rightStyles["right"]}>

                <Select
                    options={options}
                    components={{
                        IndicatorSeparator: () => null,
                    }}
                    styles={{
                        dropdownIndicator: (base) => ({
                            ...base,
                            margin: 0,
                            padding: 0,
                            width: 15,
                            height: 'auto',
                            color: "black",
                            display: 'flex',
                            alignItems: 'flex-start',
                            flexWrap: 'nowrap',
                            paddingTop: 0
                        }),
                        control: (provided, state) => ({
                            ...provided,
                            boxShadow: "none",
                            border: "none",
                            borderRadius: '10px',
                            padding: 0,
                            flexWrap: 'nowrap'
                        }),
                        menu: (provided) => ({
                            ...provided,
                            border: "none",
                            boxShadow: 'none',
                            borderWidth: 0,
                            padding: 0,
                            display: 'flex',
                            flexWrap: 'nowrap',
                            width: '100%',
                        }),
                        container: (base) => ({
                            ...base,
                            borderRadius: '10px',
                            width: '72px',
                            height: '45px',
                            padding: '3.6px',
                            display: 'flex',
                            alignItems: 'flex-start',
                            flexWrap: 'nowrap !important',
                        }),
                        option: (base) => ({
                            ...base,
                            padding: 5,
                            display: 'flex',
                            alignItems: 'flex-start',
                            flexWrap: 'nowrap !important',
                            width: '100%',
                        }),
                        valueContainer: (base) => ({
                            ...base,
                            padding: 3,
                            width: '100%',
                        }),
                        indicatorsContainer: (base) => ({
                            ...base,
                            flexWrap: 'nowrap',
                            height: 'auto'
                        }),
                        menuList: (base) => ({
                            ...base,
                            width: '100%',
                        })

                    }}
                    onMouseOver={() => props.setButtonActive(true)}
                    onMouseOut={() => props.setButtonActive(false)}
                    id="language"
                    isSearchable={false}
                    style={{ zIndex: 900 }}
                    value={options[selectIndex]}
                    className={rightStyles['language-selector']}
                    onChange={(event) => setLanguage(event.value)}
                />
                <div style={{ width: '45px', height: !isMobileScreen() ? '90px' : 0, marginTop: 0, marginBottom: !isMobileScreen() ? 10 : 0, paddingRight: 0, marginRight: 0 }}>
                    {props.children}
                </div>

                <button onMouseOver={() => props.setButtonActive(true)} onMouseOut={() => props.setButtonActive(false)} className={`${rightStyles['button-right-style']} ${props.currentElevation === 0 ? '' : rightStyles['active']}`} onClick={() => props.mapStyle(0)}>{t('3dButton')}</button>
                <button onMouseOver={() => props.setButtonActive(true)} onMouseOut={() => props.setButtonActive(false)} className={`${rightStyles['button-right-style']} ${props.currentMapStyle === 'mapbox://styles/mapbox/satellite-streets-v11' ? rightStyles['active'] : ''}`} onClick={() => props.mapStyle(1)}>{t('satteliteButton')}</button>
                {!popupMobileDevice() && (
                    <button className={`${rightStyles["button-right-style"]} ${rightStyles['w-svg']}`}
                        onClick={() => downloadGPX(routes.name, `${API_URL}${routes.gpx.contentUrl}`)}>{<GpxD id="gpxButtonIcon" fill="#212548" style={{ marginRight: 2 }} />} {t('gpxRightButton')}</button>
                )}
                {false && !popupMobileDevice() && (
                    <div>

                        <button className={`${rightStyles["button-right-style"]} ${rightStyles['w-svg']}`}
                            onClick={() => setPopupOpen(!popupOpen)}
                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 12 }}>
                            {<Mobile id="mobileButtonIcon" fill="#212548" style={{ marginRight: 3 }} />} {t('mobileButton')}
                        </button>


                        {popupOpen && (
                            <div>
                                <div style={{ backgroundColor: 'white', padding: '10px', boxSizing: 'revert', borderRadius: '5%', width: '150px', position: 'absolute', top: document.getElementById('root').clientHeight / 3, left: -document.getElementById("root").clientWidth / 2 }}>
                                    <div style={{ display: 'block' }}>
                                        <button className={styled["back-more-info"]}
                                            onClick={() => { setPopupOpen(false) }}
                                            style={{
                                                display: 'flex', float: 'none', backgroundColor: 'white', justifyContent: 'center', alignItems: 'center',
                                                position: 'relative', top: -35, left: 140, margin: 0, width: 40, height: 40, marginTop: 5, padding: 0
                                            }}>
                                            <Close style={{ color: '#212548', transform: 'scale(2.5)', stroke: '#212548' }} cursor="pointer" />
                                        </button>
                                    </div>

                                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: -35 }}>
                                        <img src={`${API_URL}${routes.qrCode.contentUrl}`} width={120} alt="Qr Code" />
                                    </div>
                                    <div>
                                        <p style={{ textAlign: 'left', color: 'black', margin: '0px 15px', fontSize: 10, lineHeight: 1.5 }}>
                                            {t('qrCodeText')}
                                            </p>
                                    </div>

                                </div>
                            </div>
                        )}

                    </div>

                )}

                {popupMobileDevice() && (
                    <button className={`${rightStyles["button-right-style"]} ${rightStyles['w-svg']}`}
                        onClick={() => {
                            const link = document.createElement('a');
                            link.setAttribute('href', `https://tours.guidos.fun?uuid=${routes['@id'].split('/')[2]}`);
                            link.setAttribute("target", "_blank");
                            link.click();
                        }}
                        onLoad={() => {
                            document.getElementById("smallMobileIcon").setAttribute("fill", '#212548');
                        }}
                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                        {<Mobile id="smallMobileIcon" fill="#212548" style={{ marginRight: 3 }} />} {t('mobileButton')}
                    </button>
                )}

            </div>
        </Suspense>
    )
}

export default RightButtons;