import 'mapbox-gl/dist/mapbox-gl.css'
import Container from './components/UI/Container'
import './App.scss';
import './components/Style/overwrites.css';

const App = () => {

  return (
    <Container />
  )
}

export default App;
